
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :label-width="'100px'">
        <el-row>
          <el-col :span="24" style="height:400px;">
            <!-- <baidu-map
              class="bm-view"
              :center="mapCenter"
              :zoom="mapZoom"
              @dblclick="ondblclick"
              @dblclick="ondblclick"
              :showAddressBar="true"
              :autoLocation="true"
              :double-click-zoom="false"
              :scroll-wheel-zoom="true"
            >
              <bm-marker :position="pointPosition" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
            </baidu-map> -->
             <el-amap 
                  ref="map" 
                  vid="amapDemo" 
                  :amap-manager="amapManager" 
                  :center="pointPosition" 
                  :zoom="zoom" 
                  :events="events" 
                  class="amap-demo">
                    <el-amap-marker vid="amapDemo" :position="pointPosition"></el-amap-marker>
                </el-amap>
          </el-col>
          <el-col :span="24">
            <el-input v-model="formModel.longtitude" readonly style="width:20%"></el-input>
            <el-input v-model="formModel.latitude" readonly style="width:20%"></el-input>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import punchLocationApi from "@/api/base/punchLocation";
import AMap from "vue-amap";
let amapManager = new AMap.AMapManager();

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      amapManager,
      zoom: 12,
      formModel: {},
      showDialog: true,
      loading: false,
      submitting: false,
      canQuery: true,
      mapZoom: 12,
      pointPosition: [112.240222, 30.337053],
      showPosition: false,
      events: {
        click: e => {
          var pt = e.lnglat;//点击选择新地址为中心点
          console.log(pt);
          
          this.pointPosition = [pt.lng, pt.lat];
          this.formModel.longtitude = pt.lng;
          this.formModel.latitude = pt.lat;
        }
      }
    };
  },
  methods: {
    mapLoad(longtitude, latitude) {
      this.pointPosition = [longtitude,latitude];
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return punchLocationApi.add(self.formModel);
            } else {
              return punchLocationApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    (function() {
      if (self.businessKey.length == 0) {
        self.$message({
          message: "查无学校编号",
          type: "warning"
        });
        return null;
      } else {
        return punchLocationApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          
          if(self.formModel.longtitude!=null && self.formModel.latitude!=null){
            this.mapLoad(self.formModel.longtitude, self.formModel.latitude);
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>
<style>
.bm-view {
  width: 100%;
  height: 400px;
}

.amap-demo{
  width: 100%;
  height: 400px;
}
</style>