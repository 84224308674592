
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="学校" prop="companyId">
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="打卡点名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入打卡点名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="备注描述" prop="remark">
          <el-input v-model="formModel.remark" placeholder="请输入备注描述" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import punchLocationApi from "@/api/base/punchLocation";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        companyId: [{ required: true, message: "请选择学校", trigger: "blur" }],
        name: [
          { required: true, message: "打卡点名称不能为空", trigger: "blur" }
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
  },
  methods: {
    getSelectedValue(value) {
      this.formModel.companyId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return punchLocationApi.add(self.formModel);
            } else {
              return punchLocationApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      if (self.businessKey.length == 0) {
        return punchLocationApi.create();
      } else {
        return punchLocationApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          var isEnable = self.formModel.isEnable;
          if (isEnable != "") {
            self.formModel.isEnable = true;
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>