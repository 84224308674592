<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/punchLocation">打卡点管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="学校" prop="companyId">
        <el-select-tree
          size="mini"
          :props="props"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree>&nbsp;
        <el-checkbox v-model="queryModel.subordinate">是否包含下级单位</el-checkbox>
      </el-form-item>
      <el-form-item label="打卡点名称" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="是否启用" prop="isEnable">
        <el-select
          v-model="queryModel.isEnable"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:120px"
        >
          <el-option value label="全部"></el-option>
          <el-option value="1" label="启用"></el-option>
          <el-option value="0" label="停用"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" :index="indexMethod" width="50"></el-table-column>
      <el-table-column prop="isEnable" label="启用状态" width="100">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.isEnable"
            @change="enabledTo(row,row.isEnable)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="companyName" label="学校" width="180"></el-table-column>
      <el-table-column prop="name" sort-by="name_" label="打卡点名称" width="180"></el-table-column>
      <el-table-column prop="longtitude" label="打卡点经度" width="100"></el-table-column>
      <el-table-column prop="latitude" label="打卡点维度" width="100"></el-table-column>
      <el-table-column prop="remark" sort-by="remark_" label="备注" width="180"></el-table-column>
      <el-table-column label="操作" width="300px">
        <template slot-scope="{row}">
          <el-row>
            <el-col :span="4">
              <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
            </el-col>
            <el-col :span="4">
              <el-link type="danger" :underline="false" @click="handleDelete(row)">删除</el-link>
            </el-col>
            <el-col :span="4">
              <el-link type="primary" :underline="false" @click="handlePoint(row)">定位</el-link>
            </el-col>
            <el-col :span="6">
              <el-popover
                placement="left"
                width="300"
                trigger="click"
                @show="openQRCode(row)"
                @hide="closeQRCode(row)"
              >
                <div :ref="'qrcode_' + row.id" style="width:300px;height:300px;"></div>
                <el-link type="primary" :underline="false" slot="reference">二维码展示</el-link>
              </el-popover>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <punchLocation-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></punchLocation-detail>
    <punchLocation-map
      v-if="showMapModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></punchLocation-map>
  </div>
</template>
<script>
import Constant from "@/constant";
import PunchLocationDetail from "./punchLocation-detail";
import PunchLocationMap from "./punchLocation-amap";
import companyInfoApi from "@/api/base/companyInfo";
import punchLocationApi from "@/api/base/punchLocation";
import QRCode from "qrcodejs2";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BasePunchLocationList",
  components: {
    "punchLocation-detail": PunchLocationDetail,
    "el-select-tree": SelectTree,
    "punchLocation-map": PunchLocationMap
  },
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: "",
        name: "",
        longtitude: "",
        latitude: "",
        remark: "",
        isEnable: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      showMapModal: false
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
  },
  methods: {
    getSelectedValue(value) {
      this.queryModel.companyId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);
      formData.append("companyId", self.queryModel.companyId);
      formData.append("name", self.queryModel.name);
      formData.append("longtitude", self.queryModel.longtitude);
      formData.append("latitude", self.queryModel.latitude);
      formData.append("remark", self.queryModel.remark);
      formData.append("isEnable", self.queryModel.isEnable);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      punchLocationApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          punchLocationApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        punchLocationApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showMapModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    openQRCode(record) {
      //el.innerHTML = "";
      var self = this;
      self.$refs["qrcode_" + record.id].innerHTML = "";
      var qrtext = process.env.VUE_APP_PUNCHLOCATION_URL + "?id=" + record.id;
      var el = self.$refs["qrcode_" + record.id];

      var qr = new QRCode(el, {
        text: qrtext,
        width: 300,
        height: 300,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
      });
    },
    closeQRCode(record) {
      self.$refs["qrcode_" + record.id].innerHTML = "";
    },
    handlePoint(record) {
      this.modalTitle = "定位";
      this.businessKey = record.id;
      this.showMapModal = true;
    },
    enabledTo(row, type, value) {
      var self = this;
      this.$confirm("是否执行操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //self.loading = true;
          if (!self.disabled) {
            var formData = new FormData();
            formData.append("id", row.id);

            punchLocationApi.changeEnable(formData).then(function(response) {
              var jsonData = response.data;

              if (jsonData.result) {
                console.log(jsonData.result);
              } else {
                self.changePage(self.pageIndex);
                self.$message({
                  type: "error",
                  message: jsonData.message
                });
              }
            });
            self.disabled = true;

            setTimeout(function() {
              self.disabled = false;
              //self.loading = false;
            }, 1000); //一秒内不能重复点击
          } else {
            self.$message({
              type: "success",
              message: "操作过快!"
            });
          }
        })
        .catch(() => {
          // self.loading = false;
          if (row.faceEnabled) {
            row.faceEnabled = false;
          } else {
            row.faceEnabled = true;
          }
        });
    }
  },
  mounted: function() {
    this.changePage(1);
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>