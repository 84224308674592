import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/punchLocation/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/punchLocation/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/punchLocation/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/punchLocation/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/punchLocation/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/punchLocation/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/punchLocation/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function changeEnable(formData) {
  return request.post(constant.serverUrl + "/base/punchLocation/changeEnable", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,changeEnable
}